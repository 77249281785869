import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"

const LupusResources = () => (
	<Layout>
		<SEO title="Resources to help you manage your lupus" />
		<h1>Useful contacts and tools to help with your lupus</h1>
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
			<Tile text="Useful Contacts" path="/useful-contacts" />
			<Tile text="Helpful Tools" path="/helpful-tools" />
			<Tile text="Latest Research" path="/latest-lupus-research" />
		</div>
	</Layout>
)

export default LupusResources
